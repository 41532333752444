import React, {useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Stack} from '@mui/material';

import {isEmpty} from 'lodash';

import CancelButton from '../../../base/components/button/CancelButton';
import {KitePath} from '../constants';
import {WidgetFormData} from '../types';
import WidgetAppearanceColorField from './fields/WidgetAppearanceColorField';
import WidgetButtonField from './fields/WidgetButtonField';
import WidgetNameField from './fields/WidgetNameField';
import WidgetQuickRepliesFieldset from './fields/WidgetQuickRepliesFieldset';
import WidgetTargetField from './fields/WidgetTargetField';
import WidgetTitleField from './fields/WidgetTitleField';
import WidgetTopSubtitleField from './fields/WidgetTopSubtitleField';
import WidgetTopTitleField from './fields/WidgetTopTitleField';
import WidgetWelcomeMessagesFieldset from './fields/WidgetWelcomeMessagesFieldset';
import WidgetWorkHoursFieldset from './fields/WidgetWorkHoursFieldset';
import SectionTitle from './sections/SectionTitle';
import SettingsAccordion from './sections/SettingsAccordion';

interface WidgetFormProps {
  form: UseFormReturn<WidgetFormData>;
  onSubmit: (data: WidgetFormData) => void;
}

interface SectionsState {
  appearance?: boolean;
  palette?: boolean;
  workHoursTarget?: boolean;
  workHoursRules?: boolean;
  workHoursWelcomeMessages?: boolean;
  outOfWorkHoursTarget?: boolean;
  outOfWorkHoursWelcomeMessages?: boolean;
}

export default function WidgetForm(props: WidgetFormProps) {
  const navigate = useNavigate();
  const {form, onSubmit} = props;
  const {handleSubmit, formState, watch} = form;
  const {isSubmitting, errors} = formState;

  const id = watch('id');
  const workHoursRules = watch('workHoursRules') || [];
  const workHoursWelcomeMessages = watch('workHoursWelcomeMessages') || [];
  const outOfWorkHoursWelcomeMessages = watch('outOfWorkHoursWelcomeMessages') || [];
  const isWorkHoursEnabled = (workHoursRules?.length || 0) > 0;

  const onCancelClick = () => {
    navigate(KitePath.base);
  };

  const [sectionsState, setSectionsState] = useState<SectionsState>({appearance: true, workHoursTarget: true});

  const toggleSection = (section: keyof SectionsState) => {
    setSectionsState({...sectionsState, [section]: !sectionsState[section]});
  };

  const onInvalid = (errors: typeof formState.errors) => {
    const sectionsToOpen: SectionsState = {};

    if (errors.appearance?.text) {
      sectionsToOpen.appearance = true;
    }

    if (errors.appearance?.pallete) {
      sectionsToOpen.palette = true;
    }

    if (errors.workHoursTarget) {
      sectionsToOpen.workHoursTarget = true;
    }

    if (errors.workHoursRules) {
      sectionsToOpen.workHoursRules = true;
    }

    if (errors.workHoursWelcomeMessages || errors.workHoursQuickReplies) {
      sectionsToOpen.workHoursWelcomeMessages = true;
    }

    if (errors.outOfWorkHoursTarget) {
      sectionsToOpen.outOfWorkHoursTarget = true;
    }

    if (errors.outOfWorkHoursWelcomeMessages || errors.outOfWorkHoursQuickReplies) {
      sectionsToOpen.workHoursWelcomeMessages = true;
    }

    if (!isEmpty(sectionsToOpen)) {
      setSectionsState({...sectionsState, ...sectionsToOpen});
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit, onInvalid)}>
      <Box mt={2}>
        <Box mt={2} width="50%">
          <WidgetNameField form={form} />
        </Box>

        <Box mt={2}>
          <SettingsAccordion
            expanded={sectionsState.appearance || false}
            onChange={() => toggleSection('appearance')}
            title="Appearance Settings"
            subtitle="Customize the content displayed on the widget's Welcome Screen to create an engaging introduction for users.">
            <Box sx={{width: '60%'}}>
              <Box>
                <WidgetTitleField form={form} />
              </Box>
              <Box mt={3}>
                <WidgetTopTitleField form={form} />
              </Box>
              <Box mt={3}>
                <WidgetTopSubtitleField form={form} />
              </Box>
              <Box mt={3}>
                <WidgetButtonField form={form} />
              </Box>
            </Box>
          </SettingsAccordion>
        </Box>

        <SettingsAccordion
          expanded={sectionsState.palette || false}
          onChange={() => toggleSection('palette')}
          title="Palette Settings"
          subtitle="Aligning the colors with your brand ensures a clear, consistent brand identity.">
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
            <Stack width="50%" justifyContent="center">
              <Box sx={{fontSize: '12px'}}>Widget background color, used in the header</Box>
              <WidgetAppearanceColorField
                form={form}
                path="appearance.pallete.backgroundColor"
                defaultColor="rgba(22, 123, 200, 1)"
              />
            </Stack>
            <Stack width="50%" justifyContent="center">
              <Box sx={{fontSize: '12px'}}>Action color, used to highlight buttons and links</Box>
              <WidgetAppearanceColorField
                form={form}
                path="appearance.pallete.accentColor"
                defaultColor="rgba(22, 123, 200, 1)"
              />
            </Stack>
          </Stack>
        </SettingsAccordion>

        {isWorkHoursEnabled && <SectionTitle mt={3} title="Working Hours" />}

        <SettingsAccordion
          expanded={sectionsState.workHoursRules || false}
          onChange={() => toggleSection('workHoursRules')}
          title="Working Hours Settings"
          subtitle="Choose your business hours to align widget availability with operational hours.">
          <WidgetWorkHoursFieldset form={form} />
        </SettingsAccordion>

        <SettingsAccordion
          expanded={sectionsState.workHoursTarget || false}
          onChange={() => toggleSection('workHoursTarget')}
          title="Routing Settings"
          subtitle="Route incoming conversations to an agent or a bot and welcome your customers with predefined messages.">
          <WidgetTargetField
            key={isWorkHoursEnabled ? 'target_work_hours' : 'target_regular'}
            form={form}
            path="workHoursTarget"
          />
        </SettingsAccordion>

        <SettingsAccordion
          expanded={sectionsState.workHoursWelcomeMessages || false}
          onChange={() => toggleSection('workHoursWelcomeMessages')}
          title="Welcome Messages Settings"
          subtitle="Welcome your customers with predefined messages.">
          <WidgetWelcomeMessagesFieldset form={form} path="workHoursWelcomeMessages" />
          {workHoursWelcomeMessages.length > 0 && (
            <>
              <Box
                sx={{background: 'rgba(174, 175, 177, 1)', marginTop: '24px', marginBottom: '24px', height: '1px'}}
              />
              <Box sx={{fontWeight: '500', fontSize: '14px', lineHeight: '24px'}}>Quick Replies</Box>
              <WidgetQuickRepliesFieldset
                form={form}
                path="workHoursQuickReplies"
                messagesPath="workHoursWelcomeMessages"
              />
            </>
          )}
        </SettingsAccordion>

        {isWorkHoursEnabled && (
          <>
            <SectionTitle mt={3} title="Out Of Working Hours" />

            <SettingsAccordion
              expanded={sectionsState.outOfWorkHoursTarget || false}
              onChange={() => toggleSection('outOfWorkHoursTarget')}
              title="Routing Settings (Out Of Working Hours)"
              subtitle="Route incoming conversations to an agent or a bot and welcome your customers with predefined messages.">
              <WidgetTargetField form={form} path="outOfWorkHoursTarget" />
            </SettingsAccordion>

            <SettingsAccordion
              expanded={sectionsState.outOfWorkHoursWelcomeMessages || false}
              onChange={() => toggleSection('outOfWorkHoursWelcomeMessages')}
              title="Welcome Messages Settings (Out Of Working Hours)"
              subtitle="Welcome your customers with predefined messages.">
              <WidgetWelcomeMessagesFieldset form={form} path="outOfWorkHoursWelcomeMessages" />
              {outOfWorkHoursWelcomeMessages.length > 0 && (
                <>
                  <Box
                    sx={{background: 'rgba(174, 175, 177, 1)', marginTop: '24px', marginBottom: '24px', height: '1px'}}
                  />
                  <Box sx={{fontWeight: '500', fontSize: '14px', lineHeight: '24px'}}>Quick Replies</Box>
                  <WidgetQuickRepliesFieldset
                    form={form}
                    path="outOfWorkHoursQuickReplies"
                    messagesPath="outOfWorkHoursWelcomeMessages"
                  />
                </>
              )}
            </SettingsAccordion>
          </>
        )}
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={6}>
        <CancelButton
          sx={{width: '96px'}}
          disabled={isSubmitting}
          onClick={onCancelClick}
          variant="contained"
          disableElevation
          size="medium">
          Cancel
        </CancelButton>
        <LoadingButton
          loading={isSubmitting}
          sx={{ml: 2, width: '96px'}}
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          size="medium">
          {id ? 'Save' : 'Add'}
        </LoadingButton>
      </Box>
    </form>
  );
}
