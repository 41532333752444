import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import timezones from 'timezones-list';

import {WidgetFormData} from '../../types';

interface WidgetWorkHoursTimezoneFieldProps {
  form: UseFormReturn<WidgetFormData>;
}

export default function WidgetWorkHoursTimezoneField(props: WidgetWorkHoursTimezoneFieldProps) {
  const {form} = props;
  const {control, formState, trigger} = form;
  const {isSubmitting} = formState;

  return (
    <Controller
      control={control}
      name="workHoursRulesTimeZone"
      rules={{required: true}}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <FormControl sx={{width: '100%'}} size="small" disabled={isSubmitting} required>
          <InputLabel id={`${name}-label`}>Time zone</InputLabel>
          <Select
            disabled={isSubmitting}
            labelId={`${name}-label`}
            id={name}
            value={value}
            label="Time zone"
            onChange={(event) => {
              onChange(event);
              trigger(name);
            }}
            onBlur={onBlur}
            inputRef={ref}>
            {timezones.map((tz) => (
              <MenuItem key={tz.tzCode} value={tz.tzCode}>
                {tz.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
