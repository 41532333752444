import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {CircularProgress} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import {get} from 'lodash';

import useListKiteServicesQuery from '../../hooks/useListKiteServicesQuery';
import {WidgetFormData} from '../../types';

interface WidgetTargetServiceFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: 'workHoursTarget.service' | 'outOfWorkHoursTarget.service';
}

export default function WidgetTargetServiceField(props: WidgetTargetServiceFieldProps) {
  const {form, path} = props;
  const {control, formState, setValue} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path);

  const servicesQuery = useListKiteServicesQuery();
  const services = servicesQuery.data?.services || [];

  const {isLoading} = servicesQuery;

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  return (
    <Controller
      control={control}
      name={`${path}.id`}
      rules={{required: true}}
      render={({field: {onBlur, value, name, ref}}) => (
        <FormControl sx={{width: '100%'}} size="small" error={!!error} disabled={isSubmitting} required>
          <InputLabel id={`${name}-label`}>Service</InputLabel>
          <Select
            disabled={services.length === 0 || isSubmitting}
            labelId={`${name}-label`}
            id={name}
            value={value || ''}
            label="Service"
            onChange={(event) => {
              const id = event.target.value;
              const service = services.find((item) => item.id === id);

              if (service) {
                setValue(path, {id: service.id, uri: service.uri});
              }
            }}
            onBlur={onBlur}
            inputRef={ref}>
            {services.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
