import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {get} from 'lodash';

import {WidgetFormData} from '../../types';

interface WidgetTitleFieldProps {
  form: UseFormReturn<WidgetFormData>;
}

export default function WidgetTitleField(props: WidgetTitleFieldProps) {
  const {form} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, 'appearance.text.title');

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          fullWidth
          label="Title"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="appearance.text.title"
      rules={{
        minLength: {
          value: 1,
          message: 'Title must be at least 2 characters long.',
        },
        maxLength: {
          value: 50,
          message: 'Title must be no more than 50 characters long.',
        },
      }}
    />
  );
}
