import React from 'react';

import IntegrationCardLayout from '../../../base/components/IntegrationCardLayout';
import KiteIcon from '../../../base/icons/KiteIcon';
import {IntegrationStatusType} from '../../../base/types';
import {KITE_SHORT_DESCRIPTION, KITE_TITLE} from '../../../texts';
import {KitePath} from '../constants';

export default function KiteCard() {
  return (
    <IntegrationCardLayout
      icon={<KiteIcon width={50} height={50} />}
      title={KITE_TITLE}
      description={KITE_SHORT_DESCRIPTION}
      href={KitePath.base}
      status={IntegrationStatusType.HIDDEN}
      ribbon="New"
      apps={['x-bees']}
    />
  );
}
