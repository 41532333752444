import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box} from '@mui/material';

import {WidgetFormData} from '../../types';
import WidgetWorkHoursRulesFieldset from './WidgetWorkHoursRulesFieldset';
import WidgetWorkHoursTimezoneField from './WidgetWorkHoursTimezoneField';

interface WidgetWorkHoursFieldsetProps {
  form: UseFormReturn<WidgetFormData>;
}

export default function WidgetWorkHoursFieldset(props: WidgetWorkHoursFieldsetProps) {
  const {form} = props;
  const {control, formState, watch, setValue} = form;
  const {errors, isSubmitting} = formState;
  const rules = watch('workHoursRules');

  return (
    <Box>
      <WidgetWorkHoursRulesFieldset form={form} />
      {rules?.length && (
        <>
          <Box sx={{background: 'rgba(174, 175, 177, 1)', marginTop: '24px', marginBottom: '24px', height: '1px'}} />
          <Box sx={{width: '50%'}}>
            <WidgetWorkHoursTimezoneField form={form} />
          </Box>
        </>
      )}
    </Box>
  );
}
