import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {get} from 'lodash';

import {WidgetFormData} from '../../types';

interface WidgetTargetAgentFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: 'workHoursTarget.agent.email' | 'outOfWorkHoursTarget.agent.email';
}

export default function WidgetTargetAgentField(props: WidgetTargetAgentFieldProps) {
  const {form, path} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path as any);

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          fullWidth
          required
          label="Email"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name={path}
      rules={{
        required: 'This field is required.',
        minLength: {
          value: 2,
          message: 'Title must be at least 2 characters long.',
        },
        maxLength: {
          value: 50,
          message: 'Title must be no more than 50 characters long.',
        },
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: 'Invalid email format.',
        },
      }}
    />
  );
}
