import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';
import Button from '@mui/material/Button';

import {UpdateWidgetCommand} from '@wildix/xbees-kite-client';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import getXbsKiteClient from '../../../base/helpers/getXbsKiteClient';
import KiteIcon from '../../../base/icons/KiteIcon';
import {KITE_TITLE} from '../../../texts';
import {KitePath} from '../constants';
import getWidgetFormDataFromWidget from '../helpers/getWidgetFormDataFromWidget';
import getWidgetFromWidgetFormData from '../helpers/getWidgetFromWidgetFormData';
import useGetKiteWidgetQuery from '../hooks/useGetKiteWidgetQuery';
import {WidgetFormData} from '../types';
import WidgetForm from './WidgetForm';

interface WidgetEditScreenParams extends Record<string, string> {
  widgetId: string;
}

export default function WidgetEditScreen() {
  const params = useParams<WidgetEditScreenParams>();
  const navigate = useNavigate();
  const form = useForm<WidgetFormData>({});
  const {setError} = form;
  const [initialized, setInitialized] = useState(false);

  const queryClient = useQueryClient();
  const queryWidgetInfo = useGetKiteWidgetQuery(params.widgetId);

  useEffect(() => {
    if (queryWidgetInfo.data && !initialized) {
      const {widget} = queryWidgetInfo.data;

      form.reset(getWidgetFormDataFromWidget(widget));
      setInitialized(true);
    }
  }, [queryWidgetInfo.data]);

  const onSubmit = useCallback(
    async (data: WidgetFormData) => {
      const client = getXbsKiteClient();
      const options = getWidgetFromWidgetFormData(data);

      try {
        await client.send(
          new UpdateWidgetCommand({
            widgetId: params.widgetId!,
            ...options,
          }),
        );

        queryClient.resetQueries('kite/widgets');

        navigate(KitePath.base);
      } catch (error) {
        // @ts-ignore
        setError('name', {message: error.toString()});
      }
    },
    [navigate],
  );

  if (queryWidgetInfo.isError) {
    return <ApiErrorBox error={queryWidgetInfo.error} />;
  }

  return (
    <Box>
      <Box sx={{p: 2, paddingTop: 0}}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary" component={Link} to="/">
            Hub
          </Typography>
          <Typography color="text.primary" component={Link} to={KitePath.base}>
            {KITE_TITLE}
          </Typography>
          <Typography color="text.primary">
            {queryWidgetInfo.data ? queryWidgetInfo.data.widget.name : <CircularProgress size={12} />}
          </Typography>
        </Breadcrumbs>
        <Box display="flex" sx={{mt: 4, width: '842px'}}>
          <Box width="96px">
            <KiteIcon width={96} height={96} />
          </Box>
          <Box flex={1} ml={5} pb={8}>
            <Typography color="text.primary" variant="h5">
              {queryWidgetInfo.data ? queryWidgetInfo.data.widget.name : <CircularProgress size={12} />}
            </Typography>

            {queryWidgetInfo.isLoading ? (
              <Box mt={5}>
                <CircularProgress size={24} />
              </Box>
            ) : null}
            {initialized ? <WidgetForm form={form} onSubmit={onSubmit} /> : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: '20px',
          left: '960px',
          right: '20px',
          bottom: '20px',
          background: 'rgba(234, 237, 240, 1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
        }}>
        <Box>
          <iframe
            title="Preview"
            src="https://stage.app.x-bees.com/kite/36cv69ao?start=true"
            style={{width: '400px', height: '540px', backgroundColor: 'white', border: '0px', borderRadius: '8px'}}
          />
          <Box>
            <Box>Set up a website widget</Box>
            <Box>Share with customers</Box>
            <Box>
              <Button variant="contained" size="small">
                Copy to clipboard
              </Button>
              <Button variant="contained" size="small">
                Download file
              </Button>
            </Box>
            <Box>
              <a href="https://github.com/jsx-eslint" target="_blank" rel="noreferrer">
                View instructions on how to set up a website widget
              </a>
            </Box>
          </Box>
          <Box>
            <Box>Share with customers</Box>
            <Box>https://api-stage.x-bees.biz/kite?target=c8</Box>
            <Box>
              <Button variant="contained" size="small">
                Copy
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
