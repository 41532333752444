import React, {useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';
import Button from '@mui/material/Button';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import KiteIcon from '../../../base/icons/KiteIcon';
import {KITE_DESCRIPTION, KITE_TITLE} from '../../../texts';
import {KitePath} from '../constants';
import useListKiteWidgetsQuery from '../hooks/useListKiteWidgetsQuery';
import WidgetListItem from './list/WidgetListItem';

export default function KiteScreen() {
  const navigate = useNavigate();
  const query = useListKiteWidgetsQuery();

  const onAddClick = useCallback(() => {
    navigate(KitePath.add);
  }, [navigate]);

  if (query.isError) {
    return <ApiErrorBox error={query.error} />;
  }

  return (
    <Box>
      <Box sx={{p: 2, paddingTop: 0}}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary" component={Link} to="/">
            Hub
          </Typography>
          <Typography color="text.primary">{KITE_TITLE}</Typography>
        </Breadcrumbs>
        <Box display="flex" sx={{mt: 4, width: '842px'}}>
          <Box width="96px">
            <KiteIcon width={96} height={96} />
          </Box>
          <Box flex={1} ml={5} pb={8}>
            <Typography color="text.primary" variant="h5">
              Kite
            </Typography>
            <Typography color="text.primary" sx={{paddingTop: 2}}>
              {KITE_DESCRIPTION}
            </Typography>
            <Button
              sx={{mt: 2}}
              onClick={onAddClick}
              disabled={false /*query.isLoading*/}
              variant="contained"
              size="medium">
              Add new widget
            </Button>
            <Box mt={4}>
              <Typography color="text.primary" sx={{fontSize: '24px', fontWeight: '500'}}>
                Widgets
              </Typography>
            </Box>
            <Box mt={2}>
              {query.isLoading && <CircularProgress size={24} />}
              {query.isSuccess
                ? query.data.widgets.map((widget) => <WidgetListItem key={widget.id} widget={widget} />)
                : null}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: '20px',
          left: '960px',
          right: '20px',
          bottom: '20px',
          background: 'rgba(234, 237, 240, 1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
        }}>
        <Box>
          <iframe
            title="Preview"
            src="https://stage.app.x-bees.com/kite/36cv69ao?start=true"
            style={{width: '400px', height: '540px', backgroundColor: 'white', border: '0px', borderRadius: '8px'}}
          />
          <Box>
            <Box>Set up a website widget</Box>
            <Box>Share with customers</Box>
            <Box>
              <Button variant="contained" size="small">
                Copy to clipboard
              </Button>
              <Button variant="contained" size="small">
                Download file
              </Button>
            </Box>
            <Box>
              <a href="https://github.com/jsx-eslint" target="_blank" rel="noreferrer">
                View instructions on how to set up a website widget
              </a>
            </Box>
          </Box>
          <Box>
            <Box>Share with customers</Box>
            <Box>https://api-stage.x-bees.biz/kite?target=c8</Box>
            <Box>
              <Button variant="contained" size="small">
                Copy
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
